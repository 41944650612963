import React, { useCallback, useRef, useState } from 'react';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton, Stack, Typography } from '@mui/material';

import TemplateViewDrawer from './TemplateViewDrawer';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';

interface Props {
  templateList: any;
  control: any;
  name: string;
  labelKey: string;
  type: string;
  fetchExecutedNextPage?: VoidFunction;
  fetchEarlierNextPage?: VoidFunction;
  hasExecutedNextPage?: boolean;
  hasEarlierNextPage?: boolean;
  totalPageCount?: number;
  pageNumber?: number;
  totalPageCountEarlier?: number;
  pageNumberEarlier?: number;
  selectedTabValue?: string;
}

const TemplateList: React.FC<Props> = ({
  templateList,
  control,
  name,
  labelKey,
  type,
  fetchExecutedNextPage,
  fetchEarlierNextPage,
  hasExecutedNextPage,
  hasEarlierNextPage,
  totalPageCount,
  pageNumber,
  totalPageCountEarlier,
  pageNumberEarlier,
  selectedTabValue,
}) => {
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);
  const [templateId, setTemplateID] = useState<string>('');
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isFetching = useRef(false);

  const handleScroll = useCallback(
    async (e: React.UIEvent<HTMLDivElement>) => {
      const container = containerRef.current;
      if (!container) return;
      const { scrollTop = 0 } = e.currentTarget || {};
      const isAtBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 10;

      if (isAtBottom && !isFetching.current) {
        isFetching.current = true;
        try {
          if (
            selectedTabValue === 'earlier_drafts' &&
            hasEarlierNextPage &&
            totalPageCountEarlier !== undefined &&
            pageNumberEarlier !== undefined &&
            totalPageCountEarlier >= pageNumberEarlier &&
            fetchEarlierNextPage
          ) {
            await fetchEarlierNextPage();
          } else if (
            selectedTabValue === 'executed_contracts' &&
            hasExecutedNextPage &&
            totalPageCount !== undefined &&
            pageNumber !== undefined &&
            totalPageCount >= pageNumber &&
            fetchExecutedNextPage
          ) {
            await fetchExecutedNextPage();
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          isFetching.current = false;
        }
      }
      setShowScrollToTop(scrollTop > 200);
    },
    [
      selectedTabValue,
      hasEarlierNextPage,
      fetchEarlierNextPage,
      hasExecutedNextPage,
      fetchExecutedNextPage,
      totalPageCount,
      pageNumber,
      totalPageCountEarlier,
      pageNumberEarlier,
    ]
  );

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <Stack
        spacing={2}
        justifyContent="flex-start"
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          position: 'relative',
          height:
            pageNumberEarlier === 1 && pageNumber === 1
              ? 'unset'
              : 'calc(100vh - 14.8rem)',
        }}
        ref={containerRef}
        onScroll={handleScroll}
      >
        <RadioButtonGroup
          name={name}
          control={control}
          options={templateList}
          labelKey={labelKey}
          renderCustomLabel={(option: any) => (
            <Stack
              justifyContent="space-between"
              direction="row"
              width="350px"
              alignItems="center"
            >
              <Stack direction="column">
                <Typography className="title">{option?.[labelKey]}</Typography>
                <Typography className="description" variant="caption">
                  {option?.template_type}
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setOpenViewDrawer(true);
                  setTemplateID(option?.id);
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Stack>
          )}
        />
      </Stack>
      {showScrollToTop && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: '1rem',
            right: '0.7rem',
            backgroundColor: 'rgba(109, 38, 76, 0.8)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(41, 14, 28, 0.8)',
            },
          }}
        >
          <ArrowUpwardIcon sx={{ fontSize: '1rem' }} />
        </IconButton>
      )}
      <TemplateViewDrawer
        open={openViewDrawer}
        onClose={() => setOpenViewDrawer(false)}
        templateId={templateId}
        type={type}
      />
    </>
  );
};

export default TemplateList;
