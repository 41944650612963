import React, { useCallback, useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import saveAs from 'file-saver';

import DocxEditingComponent from './DocxEditingComponent';
import DocxViewerComponent from './DocxViewerComponent';
import { getS3ReferencePresignedURL } from '../../Services/Draft';

interface IProps {
  draftData: any;
  fileLink: string;
  handleOpenClauseLibraryDrawer?: VoidFunction;
  canEdit?: boolean;
  instance: any;
  setInstance: any;
  dropPoint?: any;
  setDropPoint?: any;
  addedSignatureFields?: any;
  setAddedSignatureFields?: any;
  referenceDocData?: any;
  setViewDocDetails?: any;
  isExternal?: boolean;
  setDocumentLoading?: any;
  internalSignatoryId?: string;
}

const PdfView: React.FC<IProps> = ({
  draftData,
  fileLink,
  canEdit,
  instance,
  setInstance,
  setDropPoint,
  setAddedSignatureFields,
  handleOpenClauseLibraryDrawer,
  referenceDocData,
  setViewDocDetails,
  isExternal,
  setDocumentLoading,
  internalSignatoryId,
}) => {
  const [referenceDoc, setReferenceDoc] = useState<any>();
  const [isDownloading, setIsDownloading] = useState(false);

  const zoomOut = () => {
    const zoom = instance.UI.getZoomLevel() - 0.25;
    if (zoom > 0.25) {
      instance.UI.setZoomLevel(zoom);
    }
  };

  const zoomIn = () => {
    const zoom = instance.UI.getZoomLevel() + 0.25;
    instance.UI.setZoomLevel(zoom);
  };

  const search = useCallback(() => {
    const { UI } = instance;
    UI.searchText('', {
      caseSensitive: true,
      wholeWord: true,
    });
  }, [instance]);

  const drop = useCallback(
    (e: any, docViewer: any) => {
      const scrollElement = docViewer.getScrollViewElement();
      const scrollLeft = scrollElement.scrollLeft || 0;
      const scrollTop = scrollElement.scrollTop || 0;
      setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
      e.preventDefault();
      return false;
    },
    [setDropPoint]
  );

  const dragOver = useCallback((e: any) => {
    e.preventDefault();
    return false;
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutate: viewReferenceDocument } = useMutation({
    mutationKey: ['view_reference_document_link'],
    mutationFn: getS3ReferencePresignedURL,
    onSuccess: (response: any) => {
      const url = response?.data?.presigned_url;
      const fileExtension = url.split('.').pop()?.split('?')[0] || 'file';
      if (isDownloading) {
        fetch(url)
          .then((r) => r.blob())
          .then((blobData) => {
            saveAs(
              blobData,
              `${referenceDoc?.file_name || referenceDoc?.contractName + `.${fileExtension}`}`
            );
          });
      } else if (!isDownloading && setViewDocDetails) {
        setViewDocDetails({
          access_url: url,
          dataObject: referenceDoc,
        });
      }
    },
  });

  const handleViewDocDetails = (data: any) => {
    setIsDownloading(false);
    setReferenceDoc(data?.dataObject);
    const payload = {
      file_type: 'view_document',
      link: data?.access_url,
    };
    viewReferenceDocument(payload);
  };

  const fileNameCount = useMemo(() => {
    return (
      referenceDocData?.filter(
        (referenceDoc: any) => referenceDoc?.dataObject?.file_name
      )?.length || 0
    );
  }, [referenceDocData]);

  const handleDownload = (data: any) => {
    setIsDownloading(true);
    setReferenceDoc(data?.dataObject);
    const payload = {
      file_type: 'view_document',
      link: data?.access_url,
    };
    viewReferenceDocument(payload);
  };

  return (
    <React.Fragment>
      <Stack direction="row">
        <Stack
          sx={{
            background: '#88305F14',
            borderRadius: '25px',
            padding: '2px 10px',
            flex: 1,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <IconButton
            color="primary"
            onClick={() => {
              search();
            }}
          >
            <SearchIcon /> Search
          </IconButton>
          {referenceDocData?.length > 0 && !canEdit && (
            <Stack alignSelf="center" sx={{ padding: '4px' }}>
              <Badge
                badgeContent={fileNameCount}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#A0597F',
                    color: '#fff',
                  },
                }}
              >
                <Button
                  endIcon={fileNameCount > 0 ? <ArrowDropDownIcon /> : null}
                  id="menu-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  onClick={handleClick}
                  variant={fileNameCount > 0 ? 'outlined' : 'text'}
                  sx={{ padding: '2px 10px', margin: '-2px -4px' }}
                >
                  {draftData?.contractName || draftData?.file_name}
                </Button>
              </Badge>
            </Stack>
          )}
          <Menu
            id="menu-button"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {referenceDocData?.map(
              (referenceDoc: any, index: number) =>
                (draftData?.contractName !==
                  referenceDoc?.dataObject?.contractName ||
                  draftData?.file_name !==
                    referenceDoc?.dataObject?.file_name) && (
                  <MenuItem
                    key={index}
                    onClick={handleClose}
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <Button
                      variant="text"
                      style={{
                        padding: 0,
                      }}
                      onClick={() => handleViewDocDetails(referenceDoc)}
                    >
                      {referenceDoc?.dataObject?.file_name ||
                        referenceDoc?.dataObject?.contractName}
                    </Button>
                    <Button
                      sx={{ padding: '6px', minWidth: 'unset' }}
                      onClick={() => handleDownload(referenceDoc)}
                    >
                      <FileDownloadOutlinedIcon />
                    </Button>
                  </MenuItem>
                )
            )}
          </Menu>
          <Stack direction="row">
            <IconButton
              color="primary"
              onClick={() => {
                zoomIn();
              }}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                zoomOut();
              }}
            >
              <ZoomOutIcon />
            </IconButton>
          </Stack>
        </Stack>
        {canEdit && (
          <Button variant="contained" onClick={handleOpenClauseLibraryDrawer}>
            Open Clause Library
          </Button>
        )}
      </Stack>
      <Box marginTop={1}>
        {canEdit ? (
          <DocxEditingComponent
            key={`${fileLink}-edit`}
            fileLink={fileLink}
            setInstance={setInstance}
            dragOver={dragOver}
            setAddedSignatureFields={setAddedSignatureFields}
            drop={drop}
            draftDetails={draftData}
          />
        ) : (
          <DocxViewerComponent
            key={fileLink}
            fileLink={fileLink}
            setInstance={setInstance}
            dragOver={dragOver}
            setAddedSignatureFields={setAddedSignatureFields}
            drop={drop}
            draftDetails={draftData}
            instance={instance}
            isExternal={isExternal}
            setDocumentLoading={setDocumentLoading}
            internalSignatoryId={internalSignatoryId}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default PdfView;
