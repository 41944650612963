import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Popover, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import AssigneePopoverTitle from './AssigneePopoverTitle';
import AssigneeWithDate from './AssigneeWithDate';
import NameAvatar from '../../RiverusUI/DataGrid/NameAvatar';
import { assigneesList } from '../../Services/Draft';
import Scrollable from '../../UniversalComponents/Scrollable/scrollable';

interface IProps {
  data: any;
  Params: any;
}

const AvatarList: React.FC<IProps> = ({ data, Params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [clickedItemId, setClickedItemId] = useState<string | null>(null);
  const [clickedItemEmail, setClickedItemEmail] = useState<string | null>(null);
  const [clickedDraftId, setClickedDraftId] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [clickedAssigneeType, setClickedAssigneeType] = useState<any | null>(
    null
  );
  const [approversData, setApproversData] = useState<any[]>([]);
  const [collaboratorsData, setCollaboratorsData] = useState<any[]>([]);

  const { mutate: assigneesListMutation } = useMutation({
    mutationKey: ['assignee_list'],
    mutationFn: assigneesList,
    onSuccess: (response: any, variables: any) => {
      if (variables.assignee_type === 'approvers') {
        setApproversData(response?.data);
      } else if (variables.assignee_type === 'collaborators') {
        setCollaboratorsData(response?.data);
      }
    },

    onError: () => {
      enqueueSnackbar('Error fetching assignees list!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const assigneeCollaboratorsData = useMemo(() => {
    return Object.values(collaboratorsData ?? {}).filter(
      (collaborator: any) => collaborator?.id === clickedItemId
    );
  }, [collaboratorsData, clickedItemId]);

  const assigneeApproversData = useMemo(() => {
    return Object.values(approversData ?? {}).filter(
      (approver: any) => approver?.id === clickedItemId
    );
  }, [approversData, clickedItemId]);

  const isOwner = useMemo(() => {
    return Params?.row?.owners?.some(
      (owner: any) => owner?.id === clickedItemId
    );
  }, [Params?.row?.owners, clickedItemId]);

  const creator = useMemo(() => {
    return data?.some(
      (creator: any) =>
        creator?.assigned_role?.includes('Creators') &&
        clickedItemId === creator?.id
    );
  }, [data, clickedItemId]);

  const isCreatorAndOwner = useMemo(() => {
    return Params?.row?.owners?.some((owner: any) =>
      data?.some(
        (creator: any) =>
          creator?.assigned_role?.includes('Creators') &&
          clickedItemId === owner?.id &&
          clickedItemId === creator?.id
      )
    );
  }, [Params, data, clickedItemId]);

  const draftTemplateCheck = useMemo(() => {
    return assigneeCollaboratorsData?.[0]?.data?.some(
      (collaboratorsData: any) =>
        collaboratorsData?.table === 'OptionalFields' &&
        assigneeCollaboratorsData?.[0]?.id === clickedItemId
    );
  }, [assigneeCollaboratorsData, clickedItemId]);

  const draftRequisitionCheck = useMemo(() => {
    return assigneeApproversData?.[0]?.data?.some(
      (approverData: any) =>
        approverData?.approval_type === 'requisition_approvers' &&
        assigneeApproversData?.[0]?.id === clickedItemId &&
        Params?.row?.version === 0
    );
  }, [assigneeApproversData, clickedItemId]);

  const draftPreSignatoryCheck = useMemo(() => {
    return assigneeApproversData?.[0]?.data?.some(
      (approverData: any) =>
        approverData?.approval_type === 'pre_signatory_approvers' &&
        assigneeApproversData?.[0]?.id === clickedItemId
    );
  }, [assigneeApproversData, clickedItemId]);

  const draftPolicyCheck = useMemo(() => {
    return assigneeApproversData?.[0]?.data?.some(
      (approverData: any) =>
        approverData?.deviation_type === 'kdp' &&
        assigneeApproversData?.[0]?.id === clickedItemId &&
        !Params?.row?.pre_signatories
    );
  }, [assigneeApproversData, clickedItemId]);

  const draftCustomCheck = useMemo(() => {
    return assigneeApproversData?.[0]?.data?.some(
      (approverData: any) =>
        approverData?.deviation_type === 'custom' &&
        assigneeApproversData?.[0]?.id === clickedItemId &&
        !Params?.row?.pre_signatories
    );
  }, [assigneeApproversData, clickedItemId]);

  const draftChecklistCheck = useMemo(() => {
    return assigneeCollaboratorsData?.[0]?.data?.some(
      (collaboratorsData: any) =>
        collaboratorsData?.table === 'Checklist' &&
        assigneeCollaboratorsData?.[0]?.id === clickedItemId &&
        Params?.row?.signatories.length === 0
    );
  }, [assigneeCollaboratorsData, clickedItemId]);

  const draftSignatureCheck = useMemo(() => {
    return Params?.row?.signatories?.some((signatory: any) =>
      signatory?.id && signatory?.version
        ? signatory?.id === clickedItemId &&
          signatory?.version === Params?.row?.version
        : signatory?.id
          ? signatory?.id === clickedItemId
          : signatory?.email === clickedItemEmail
    );
  }, [Params, clickedItemId, clickedItemEmail]);

  const memoizedOptionalFields = useMemo(() => {
    return (
      assigneeCollaboratorsData?.[0]?.data
        ?.filter(
          (collaboratorsData: any) =>
            collaboratorsData?.table === 'OptionalFields' &&
            assigneeCollaboratorsData?.[0]?.id === clickedItemId
        )
        .map((collaboratorsData: any) => {
          const fieldValue = collaboratorsData?.field_value;
          let status = 'Pending';
          if (
            collaboratorsData?.data_type === 'Bulleted List' ||
            collaboratorsData?.data_type === 'Numbered List'
          ) {
            const parsedFieldValue = fieldValue ? JSON.parse(fieldValue) : [];
            const filteredFieldValue = parsedFieldValue.filter(
              (data: any) => data['list'] !== ''
            );
            status = filteredFieldValue.length ? 'Completed' : 'Pending';
          } else if (collaboratorsData?.data_type === 'Boolean') {
            status = fieldValue === 'true' ? 'Completed' : 'Pending';
          } else if (fieldValue) {
            status = 'Completed';
          }

          return {
            fieldName: collaboratorsData?.field_name,
            createdOn:
              collaboratorsData?.assigned_on || collaboratorsData?.created_on,
            reminderDueDate: collaboratorsData?.due_date,
            status,
            completedDate: collaboratorsData?.modified_on,
            key: collaboratorsData?.draft_id,
          };
        }) || []
    );
  }, [assigneeCollaboratorsData, clickedItemId]);

  const memoizedRequisitionApprovers = useMemo(() => {
    return (
      assigneeApproversData?.[0]?.data
        ?.filter(
          (approverData: any) =>
            approverData?.approval_type === 'requisition_approvers'
        )
        .map((approverData: any) => ({
          createdOn: approverData?.created_on,
          reminderDueDate: approverData?.due_date,
          status:
            approverData?.status === 'approval_pending'
              ? 'Approval Pending'
              : 'Approved',
          approvedDate: approverData?.modified_on,
          key: approverData?.draft_id,
        })) || []
    );
  }, [assigneeApproversData]);

  const memoizedPreSignatoryApprovers = useMemo(() => {
    return (
      assigneeApproversData?.[0]?.data
        ?.filter(
          (approverData: any) =>
            approverData?.approval_type === 'pre_signatory_approvers'
        )
        .map((approverData: any) => ({
          createdOn: approverData?.created_on,
          reminderDueDate: approverData?.due_date,
          status:
            approverData?.status === 'approval_pending'
              ? 'Approval Pending'
              : 'Approved',
          approvedDate: approverData?.modified_on,
          key: approverData?.draft_id,
        })) || []
    );
  }, [assigneeApproversData]);

  const memoizedPolicyApprovals = useMemo(() => {
    return (
      assigneeApproversData?.[0]?.data
        ?.filter(
          (approverData: any) =>
            approverData?.deviation_type === 'kdp' &&
            assigneeApproversData?.[0]?.id === clickedItemId
        )
        .map((approverData: any) => ({
          createdOn: approverData?.created_on,
          reminderDueDate: approverData?.due_date,
          status:
            approverData?.status === 'approval_pending'
              ? 'Approval Pending'
              : 'Approved',
          approvedDate: approverData?.modified_on,
          key: approverData?.draft_id,
        })) || []
    );
  }, [assigneeApproversData]);

  const memoizedCustomApprovals = useMemo(() => {
    return (
      assigneeApproversData?.[0]?.data
        ?.filter(
          (approverData: any) =>
            approverData?.deviation_type === 'custom' &&
            assigneeApproversData?.[0]?.id === clickedItemId
        )
        .map((approverData: any) => ({
          createdOn: approverData?.created_on,
          reminderDueDate: approverData?.due_date,
          status:
            approverData?.status === 'approval_pending'
              ? 'Approval Pending'
              : 'Approved',
          approvedDate: approverData?.modified_on,
          key: approverData?.draft_id,
        })) || []
    );
  }, [assigneeApproversData]);

  const memoizedChecklist = useMemo(() => {
    return (
      assigneeCollaboratorsData?.[0]?.data
        ?.filter(
          (collaboratorsData: any) =>
            collaboratorsData?.table === 'Checklist' &&
            assigneeCollaboratorsData?.[0]?.id === clickedItemId
        )
        .map((collaboratorsData: any) => ({
          checklistName: collaboratorsData?.checklist_name,
          createdOn:
            collaboratorsData?.assigned_on || collaboratorsData?.created_on,
          reminderDueDate: collaboratorsData?.due_date,
          status: collaboratorsData?.status ? 'Completed' : 'Pending',
          completedDate: collaboratorsData?.modified_on,
          key: collaboratorsData?.draft_id,
        })) || []
    );
  }, [assigneeCollaboratorsData]);

  const memoizedSignatories = useMemo(() => {
    return Params?.row?.signatories
      ?.filter((signatory: any) =>
        signatory?.id && signatory?.version
          ? signatory?.id === clickedItemId &&
            signatory?.version === Params?.row?.version
          : signatory?.id
            ? signatory?.id === clickedItemId
            : signatory?.email === clickedItemEmail
      )
      ?.map((signatory: any, index: number) => {
        return {
          assignedDate: signatory?.assigned_date,
          reminderDueDate: signatory?.reminder?.due_date,
          status: signatory?.status
            ? signatory?.status
            : signatory?.signed_date
              ? 'Signature Signed'
              : 'Signature Pending',
          signedDate: signatory?.signed_date,
          abortedDate: signatory?.declined_date,
          key: `${index}-${signatory?.id || signatory?.email}`,
        };
      });
  }, [Params?.row?.signatories, clickedItemId, clickedItemEmail]);

  const isOptionalFieldData = useMemo(() => {
    return (
      draftTemplateCheck ||
      draftRequisitionCheck ||
      draftPreSignatoryCheck ||
      draftPolicyCheck ||
      draftCustomCheck ||
      (draftChecklistCheck && Params?.row?.signatories?.length === 0) ||
      Params?.row?.signatories?.some(
        (signatory: any) =>
          signatory?.id === clickedItemId ||
          signatory?.email === clickedItemEmail
      )
    );
  }, [
    draftTemplateCheck,
    draftRequisitionCheck,
    draftPreSignatoryCheck,
    draftPolicyCheck,
    draftCustomCheck,
    draftChecklistCheck,
    clickedItemId,
    clickedItemEmail,
    Params?.row?.signatories,
  ]);

  const getAssignmentMessage = useMemo(() => {
    if (isOwner && !isOptionalFieldData && !creator) {
      return 'the Owner.';
    } else if (!isOwner && !isOptionalFieldData && creator) {
      return 'the Creator.';
    } else if (isCreatorAndOwner && !isOptionalFieldData) {
      return 'the Creator and the Owner.';
    } else if (isCreatorAndOwner && isOptionalFieldData) {
      return 'the Creator, Owner and is assigned for:';
    } else if (isOwner && isOptionalFieldData) {
      return 'the Owner and is assigned for:';
    } else if (creator && isOptionalFieldData) {
      return 'the Creator and is assigned for:';
    } else {
      return 'assigned for:';
    }
  }, [isOwner, isOptionalFieldData, creator, isCreatorAndOwner]);

  const handleClick = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      draftId: number,
      itemId: string,
      itemEmail: string,
      itemType: any
    ) => {
      setAnchorEl(event.currentTarget);
      setClickedDraftId(draftId);
      if (itemId) {
        setClickedItemId(itemId);
      } else {
        setClickedItemEmail(itemEmail);
      }
      setClickedAssigneeType(itemType);

      const approversPayload = {
        draft_id: Params?.row?.id,
        assignee_type: 'approvers',
      };
      const collaboratorsPayload = {
        draft_id: Params?.row?.id,
        assignee_type: 'collaborators',
      };
      assigneesListMutation(approversPayload);
      assigneesListMutation(collaboratorsPayload);
    },
    [assigneesListMutation]
  );

  const handleClose = () => {
    setAnchorEl(null);
    setClickedDraftId(null);
    setClickedItemId(null);
    setClickedItemEmail(null);
    setClickedAssigneeType(null);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose();
      }
    },
    [anchorEl]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      className="custom-horizontal-scroll"
      maxWidth="11rem"
    >
      {data
        .filter(
          (item: any) =>
            item?.version === Params?.row?.version ||
            item?.version === undefined
        )
        .map((item: any, index: number) => (
          <Stack key={index}>
            <Stack
              sx={{
                cursor: 'pointer',
                padding: '2px',
                '&:hover, &.clicked': {
                  border: '2px solid #A0597F',
                  borderRadius: '50%',
                },
              }}
              className={
                (clickedItemId === item?.id ||
                  clickedItemEmail === item?.email) &&
                clickedDraftId === Params?.row?.id
                  ? 'clicked'
                  : ''
              }
            >
              <NameAvatar
                firstName={
                  item?.user_type === 'external'
                    ? item?.first_name?.split(' ')[0] || item?.name
                    : item?.first_name
                }
                lastName={
                  item?.user_type === 'external'
                    ? item?.last_name || item?.first_name?.split(' ')[1] || ''
                    : item?.last_name
                }
                onClick={(event: any) =>
                  handleClick(
                    event,
                    Params?.row?.id,
                    item?.id,
                    item?.email,
                    item?.assignee_type
                  )
                }
              />
            </Stack>
            <Popover
              open={
                Boolean(anchorEl) &&
                (clickedItemId === item?.id ||
                  clickedItemEmail === item?.email) &&
                clickedAssigneeType === item?.assignee_type &&
                clickedDraftId === Params?.row?.id
              }
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              disableRestoreFocus
            >
              <Stack padding="14px">
                <Stack spacing={1}>
                  <Typography variant="subtitle2" fontWeight={700}>
                    {`${item?.first_name || item?.name} is ${getAssignmentMessage}`}
                  </Typography>
                  <Scrollable maxHeight={400}>
                    {/* Template Field */}
                    {draftTemplateCheck && Params?.row?.version === 0 && (
                      <Stack spacing={1}>
                        <AssigneePopoverTitle title="Template Field :" />
                        {memoizedOptionalFields?.map(
                          (optionalField: any, index: number) => (
                            <Stack key={optionalField?.key}>
                              <AssigneeWithDate
                                title={`${index + 1}.) Field Name:`}
                                subTitle={optionalField?.fieldName}
                              />
                              <AssigneeWithDate
                                title="Assigned On:"
                                date={optionalField?.createdOn}
                              />
                              {optionalField?.reminderDueDate && (
                                <AssigneeWithDate
                                  title="Task Due Date:"
                                  date={optionalField?.reminderDueDate}
                                />
                              )}
                              <AssigneeWithDate
                                title="Status:"
                                subTitle={optionalField?.status}
                              />
                              {optionalField?.status === 'Completed' && (
                                <AssigneeWithDate
                                  title="Completed Date:"
                                  date={optionalField?.completedDate}
                                />
                              )}
                            </Stack>
                          )
                        )}
                      </Stack>
                    )}

                    {/* Requisition Approval */}
                    {draftRequisitionCheck && (
                      <Stack>
                        <AssigneePopoverTitle title="Requisition Approval :" />
                        {memoizedRequisitionApprovers?.map((approver: any) => (
                          <Stack key={approver?.key}>
                            <AssigneeWithDate
                              title="Assigned On:"
                              date={approver?.createdOn}
                            />
                            {approver?.reminderDueDate && (
                              <AssigneeWithDate
                                title="Task Due Date:"
                                date={approver?.reminderDueDate}
                              />
                            )}
                            <AssigneeWithDate
                              title="Status:"
                              subTitle={approver?.status}
                            />
                            {approver?.status === 'Approved' && (
                              <AssigneeWithDate
                                title="Approved Date:"
                                date={approver?.approvedDate}
                              />
                            )}
                          </Stack>
                        ))}
                      </Stack>
                    )}

                    {draftPreSignatoryCheck && (
                      <Stack>
                        <AssigneePopoverTitle title="Pre Signatory Approval :" />
                        {memoizedPreSignatoryApprovers?.map((approver: any) => (
                          <Stack key={approver?.key}>
                            <AssigneeWithDate
                              title="Assigned On:"
                              date={approver?.createdOn}
                            />
                            {approver?.reminderDueDate && (
                              <AssigneeWithDate
                                title="Task Due Date:"
                                date={approver?.reminderDueDate}
                              />
                            )}
                            <AssigneeWithDate
                              title="Status:"
                              subTitle={approver?.status}
                            />
                            {approver?.status === 'Approved' && (
                              <AssigneeWithDate
                                title="Approved Date:"
                                date={approver?.approvedDate}
                              />
                            )}
                          </Stack>
                        ))}
                      </Stack>
                    )}

                    {/* Policy Approvals */}
                    {draftPolicyCheck && (
                      <Stack>
                        <AssigneePopoverTitle title="Policy Approval :" />
                        <Stack spacing={1}>
                          {memoizedPolicyApprovals?.map(
                            (approval: any, index: number) => (
                              <Stack key={approval?.key}>
                                <AssigneeWithDate
                                  title={`${index + 1}.) Assigned On:`}
                                  date={approval?.createdOn}
                                />
                                {approval?.reminderDueDate && (
                                  <AssigneeWithDate
                                    title="Task Due Date:"
                                    subTitle={approval?.reminderDueDate}
                                  />
                                )}
                                <AssigneeWithDate
                                  title="Status:"
                                  subTitle={approval?.status}
                                />
                                {approval?.status === 'Approved' && (
                                  <AssigneeWithDate
                                    title="Approved Date:"
                                    date={approval?.approvedDate}
                                  />
                                )}
                              </Stack>
                            )
                          )}
                        </Stack>
                      </Stack>
                    )}

                    {/* Custom Approvals */}
                    {draftCustomCheck && (
                      <Stack>
                        <AssigneePopoverTitle title="Custom Approval :" />
                        <Stack spacing={1}>
                          {memoizedCustomApprovals?.map(
                            (approval: any, index: number) => (
                              <Stack key={approval?.key}>
                                <AssigneeWithDate
                                  title={`${index + 1}.) Assigned On:`}
                                  date={approval?.createdOn}
                                />
                                {approval?.reminderDueDate && (
                                  <AssigneeWithDate
                                    title="Task Due Date:"
                                    date={approval?.reminderDueDate}
                                  />
                                )}
                                <AssigneeWithDate
                                  title="Status:"
                                  subTitle={approval?.status}
                                />
                                {approval?.status === 'Approved' && (
                                  <AssigneeWithDate
                                    title="Approved Date:"
                                    date={approval?.approvedDate}
                                  />
                                )}
                              </Stack>
                            )
                          )}
                        </Stack>
                      </Stack>
                    )}

                    {/* Checklist */}
                    {draftChecklistCheck && (
                      <Stack>
                        <AssigneePopoverTitle title="Checklist :" />
                        <Stack spacing={1}>
                          {memoizedChecklist?.map(
                            (checklist: any, index: number) => (
                              <Stack key={checklist?.key}>
                                <AssigneeWithDate
                                  title={`${index + 1}.) Checklist Name:`}
                                  subTitle={checklist?.checklistName}
                                />
                                <AssigneeWithDate
                                  title="Assigned On:"
                                  date={checklist?.createdOn}
                                />
                                {checklist?.reminderDueDate && (
                                  <AssigneeWithDate
                                    title="Task Due Date:"
                                    date={checklist?.reminderDueDate}
                                  />
                                )}
                                <AssigneeWithDate
                                  title="Status:"
                                  subTitle={checklist?.status}
                                />
                                {checklist?.status === 'Completed' && (
                                  <AssigneeWithDate
                                    title="Completed Date:"
                                    date={checklist?.completedDate}
                                  />
                                )}
                              </Stack>
                            )
                          )}
                        </Stack>
                      </Stack>
                    )}

                    {/* Signature */}
                    {draftSignatureCheck && (
                      <Stack>
                        <AssigneePopoverTitle title="Signature :" />
                        <Stack spacing={1}>
                          {memoizedSignatories?.map((signatory: any) => (
                            <Stack key={signatory?.key}>
                              <AssigneeWithDate
                                title="Assigned On:"
                                subTitle={signatory?.assignedDate}
                              />
                              {signatory?.reminderDueDate && (
                                <AssigneeWithDate
                                  title="Task Due Date:"
                                  date={signatory?.reminderDueDate}
                                />
                              )}
                              {signatory?.signedDate && (
                                <AssigneeWithDate
                                  title="Signed Date:"
                                  date={signatory?.signedDate}
                                />
                              )}
                              <AssigneeWithDate
                                title="Status:"
                                subTitle={signatory?.status}
                              />
                              {signatory?.abortedDate && (
                                <AssigneeWithDate
                                  title="Aborted Date:"
                                  date={signatory?.abortedDate}
                                />
                              )}
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    )}
                  </Scrollable>
                </Stack>
              </Stack>
            </Popover>
          </Stack>
        ))}
    </Stack>
  );
};

export default AvatarList;
